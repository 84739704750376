@import '../../styles/breakpoints';

// general body type style rules
@mixin body-general {
    font-family: Inter Variable, Montserrat, Helvetica, sans-serif;
    font-weight: inherit;
    line-height: 1.2;
}

// mixins for body type styles
@mixin body-3xs {
    @include body-general;
    font-size: 8px;
    line-height: 1;
}

@mixin body-2xs {
    @include body-general;
    font-size: 12px;
    line-height: 1.04;
}

@mixin body-xs {
    @include body-general;
    font-size: 14px;
    line-height: 1.24;
}

@mixin body-md {
    @include body-general;
    font-size: 18px;
    line-height: 1.24;
}

@mixin body-lg {
    @include body-general;
    font-size: 18px;
    line-height: 1.24;
}

@mixin body-20 {
    @include body-general;
    font-size: 20px;
    line-height: 1.24;
}

@mixin body-xl {
    @include body-general;
    font-size: 22px;
}

@mixin body-2xl {
    @include body-general;
    font-size: 32px;
}

@mixin body-3xl {
    @include body-general;
    font-size: 36px;
}

@mixin large-prompt {
    @include body-general;
    font-size: 28px;
}

// generate default body type style classes
.body-3xs {
    @include body-3xs;
}

.body-2xs {
    @include body-2xs;
}

.body-xs {
    @include body-xs;
}

.body-md {
    @include body-md;
}

.body-lg {
    @include body-lg;
}

.body-20 {
    @include body-20;
}

.large-prompt {
    @include large-prompt();
}

.body-xl {
    @include body-xl;
}

.body-2xl {
    @include body-2xl;
}

.body-3xl {
    @include body-3xl;
}

// generate responsive body styles
@include responsive-class('body-3xs') {
    @include body-3xs;
}
@include responsive-class('body-2xs') {
    @include body-2xs;
}
@include responsive-class('body-xs') {
    @include body-xs;
}
@include responsive-class('body-md') {
    @include body-md;
}
@include responsive-class('body-lg') {
    @include body-lg;
}
@include responsive-class('body-20') {
    @include body-20;
}
@include responsive-class('body-xl') {
    @include body-xl;
}
@include responsive-class('body-2xl') {
    @include body-2xl;
}
@include responsive-class('body-3xl') {
    @include body-3xl;
}

@include responsive-class('large-prompt') {
    @include large-prompt;
}

// general heading type style rules
@mixin heading-general {
    font-family: Inter Variable, sans-serif;
    font-weight: 900;
    letter-spacing: -0.01em;
    line-height: 0.98;
}

// mixins for body type styles
@mixin heading-xxs {
    @include heading-general;
    font-size: 28px;
    font-weight: 400;
    line-height: 1;
}

@mixin heading-xs {
    @include heading-general;
    font-size: 40px;
}

@mixin heading-sm {
    @include heading-general;
    font-size: 54px;
}

@mixin heading-md {
    @include heading-general;
    font-size: 65px;
}

@mixin heading-lg {
    @include heading-general;
    font-size: 74px;
}

@mixin heading-xl {
    @include heading-general;
    font-size: 82px;
}

@mixin heading-2xl {
    @include heading-general;
    font-size: 100px;
}

@mixin heading-3xl {
    @include heading-general;
    font-size: 128px;
}

@mixin heading-4xl {
    @include heading-general;
    font-size: 168px;
}

@mixin heading-5xl {
    @include heading-general;
    font-size: 200px;
}

@mixin heading-60 {
    @include heading-general;
    font-size: 60px;
}
@mixin heading-70 {
    @include heading-general;
    font-size: 70px;
}

@mixin heading-90 {
    @include heading-general;
    font-size: 90px;
}

@mixin heading-120 {
    @include heading-general;
    font-size: 120px;
}

@mixin heading-128 {
    @include heading-general;
    font-size: 128px;
}

@mixin heading-150 {
    @include heading-general;
    font-size: 150px;
}

// generate default body type style classes
.heading-xxs {
    @include heading-xxs;
}

.heading-xs {
    @include heading-xs;
}

.heading-sm {
    @include heading-sm;
}

.heading-md {
    @include heading-md;
}

.heading-lg {
    @include heading-lg;
}

.heading-xl {
    @include heading-xl;
}

.heading-2xl {
    @include heading-2xl;
}

.heading-3xl {
    @include heading-3xl;
}

.heading-4xl {
    @include heading-4xl;
}

.heading-5xl {
    @include heading-5xl;
}

.heading-60 {
    @include heading-60;
}

.heading-70 {
    @include heading-70;
}

.heading-90 {
    @include heading-90;
}

.heading-120 {
    @include heading-120;
}

.heading-128 {
    @include heading-128;
}

.heading-150 {
    @include heading-150;
}

// generate responsive heading style classes
@include responsive-class('heading-xxs') {
    @include heading-xs;
}
@include responsive-class('heading-xs') {
    @include heading-xs;
}
@include responsive-class('heading-sm') {
    @include heading-sm;
}
@include responsive-class('heading-md') {
    @include heading-md;
}
@include responsive-class('heading-lg') {
    @include heading-lg;
}
@include responsive-class('heading-xl') {
    @include heading-xl;
}
@include responsive-class('heading-2xl') {
    @include heading-2xl;
}
@include responsive-class('heading-3xl') {
    @include heading-3xl;
}
@include responsive-class('heading-4xl') {
    @include heading-4xl;
}
@include responsive-class('heading-5xl') {
    @include heading-5xl;
}
@include responsive-class('heading-60') {
    @include heading-60;
}
@include responsive-class('heading-70') {
    @include heading-70;
}
@include responsive-class('heading-90') {
    @include heading-90;
}
@include responsive-class('heading-128') {
    @include heading-128;
}
@include responsive-class('heading-120') {
    @include heading-120;
}
@include responsive-class('heading-150') {
    @include heading-150;
}
