@import '../../../../styles/breakpoints';
@import '../../../../styles/dimensions';
@import '../../bento-styles.module';

.container {
    @extend %containerbase;
}

.flexContainer {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: $gap-small-medium $gap-small-medium 0 $gap-small-medium;
    width: 100%;

    @include screen-max-width('lg') {
        flex-direction: column;
        margin: 0 0 $gap-small-medium 0;
    }

    .boxOne {
        @extend %box;
        margin-bottom: 0;
        margin-right: 10px;

        @include screen-max-width('lg') {
            margin-bottom: 10px;
            margin-right: 0;
        }
    }

    .boxTwo {
        @extend %box;
        margin-bottom: 0;
        margin-left: 10px;

        @include screen-max-width('lg') {
            margin-left: 0;
            margin-right: 0;
            margin-top: 10px;
        }
    }
}
